import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  // BrowserRouter as Router,
} from "react-router-dom";
import Login from "./admin/auth/Login";
import CustomerLogin from "./customer/auth/Login";
import ForgetPassword from "./admin/auth/ForgetPassword";
import Dashboard from "./admin/Dashboard";
import CustomerDetails from "./admin/CustomerDetails";
import ProductDetails from "./admin/ProductDetails";
import CreateProject from "./admin/CreateProject";
import Help from "./customer/auth/Help";
import Setting from "./admin/Setting";
import CDashboard from "./customer/CDashboard";
import CProductDetails from "./customer/CProductDetails";

function App() {
  const userData = localStorage.getItem("userData");
  const userExist = userData ? JSON.parse(userData) : null;

  /** TO DISABLE SCREEN CAPTURE **/
  document.addEventListener("keydown", (e) => {
    if (e.key == "PrintScreen") {
      navigator.clipboard.writeText("");
      alert("Screenshots disabled!");
    }
  });

  /** TO DISABLE PRINTS WHIT CTRL+P **/
  document.addEventListener("keydown", (e) => {
    if (e.ctrlKey && e.key == "p") {
      alert("This section is not allowed to print or export to PDF");
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });

  return (
    <BrowserRouter>
      <Routes>
        {/* admin  */}
        <Route
          path="/"
          element={
            userExist == null ? (
              <Login />
            ) : userExist.userType == 1 ? (
              <Dashboard />
            ) : (
              <CDashboard />
            )
          }
        />
        <Route path="/forgotPassword" element={<ForgetPassword />} />
        <Route path="/customer/login" element={<CustomerLogin />} />
        <Route path="/help" element={<Help />} />
        <Route path="/customerDetails" element={<CustomerDetails />} />
        <Route path="/productDetails" element={<ProductDetails />} />
        <Route path="/createProject" element={<CreateProject />} />
        <Route path="/setting" element={<Setting />} />

        {/* customer  */}
        <Route path="/cproductDetails" element={<CProductDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
