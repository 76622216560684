import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import { MdDashboard, MdExpandMore } from "react-icons/md";
import { useEffect, useState } from "react";
import ApiManager from "../api/ApiManager";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";

const CDashboard = () => {
  const userData = localStorage.getItem("userData");
  const userExist = userData ? JSON.parse(userData) : null;
  const navigate = useNavigate();

  const [projectList, setprojectList] = useState<any>([]);
  const [apiLoader, setapiLoader] = useState(false);

  useEffect(() => {
    const disableRightClick = (e: { preventDefault(): any }) => {
      e.preventDefault();
      document.addEventListener("contextmenu", disableRightClick);
    };
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  });

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = () => {
    setapiLoader(true);
    const body = {
      id: userExist.id,
    };

    ApiManager.getProject(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          setprojectList(resp?.data?.data);
        } else {
        }
        setapiLoader(false);
      })
      .catch((error) => {
        console.log("error", error), setapiLoader(false);
      });
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        {/* ---------------- */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          <div className="text-gray-600 mb-2 font-semibold">Dashboard</div>
          <p className="text-sm text-gray-600 mb-4">
            Track your projects with ease in just few clicks
          </p>

          <div className="flex justify-between items-center mb-4 gap-6">
            <div className="bg-white rounded-lg shadow p-4 flex items-center w-full h-30">
              <div className="mr-4">
                <div className="text-black font-semibold">Ongoing Projects</div>
                <div className="text-2xl font-bold">{projectList.length}</div>
              </div>
              <div className="ml-auto">
                <MdDashboard size={50} className="text-gray-400" />
              </div>
            </div>
          </div>

          <span className="font-bold">Projects</span>
          <div className="bg-white rounded-lg shadow-md overflow-hidden mt-2 mb-24">
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-50 text-left text-xs sm:text-sm text-gray-500">
                    <th className="p-2 sm:p-3 w-16 sm:w-20 font-semibold">
                      Sr. No.
                    </th>
                    <th className="p-2 sm:p-3 font-semibold w-40 sm:w-80">
                      Project category{" "}
                      <MdExpandMore className="inline-block ml-1" size={14} />
                    </th>
                    <th className="p-2 sm:p-3 w-32 sm:w-45 font-semibold">
                      Ratings{" "}
                      <MdExpandMore className="inline-block ml-1" size={14} />
                    </th>
                    <th className="p-2 sm:p-3 w-28 sm:w-32 font-semibold">
                      View progress
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projectList.map((item: any, index: number) => (
                    <tr key={item.id} className="border-t border-gray-200">
                      <td className="p-2 sm:p-3 text-xs sm:text-sm">
                        {String(index + 1).padStart(2, "0")}
                      </td>
                      <td className="p-2 sm:p-3 text-xs sm:text-base font-semibold">
                        {item?.name}
                      </td>
                      <td className="p-2 sm:p-3 text-xs sm:text-base flex flex-wrap">
                        {item.specs.map((item1: any) => {
                          return (
                            <h3 key={item1.id} className="mr-2 sm:mr-3">
                              {item1?.value}
                            </h3>
                          );
                        })}
                      </td>
                      <td className="p-2 sm:p-3">
                        <button
                          onClick={() =>
                            navigate("/cproductDetails", {
                              state: {
                                customerName: userExist.name,
                                projectId: item?.id,
                                projectName: item?.name,
                              },
                            })
                          }
                          className="w-full bg-blue-500 hover:bg-blue-600 text-white text-xs sm:text-sm py-1 px-2 rounded"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
      <Spinner loading={apiLoader} />
    </div>
  );
};

export default CDashboard;
