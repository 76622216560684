import { useEffect, useState } from "react";
import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import ProjectPhaseModal from "../components/ProjectPhaseModal";
import { useLocation } from "react-router-dom";
import ApiManager, { IMG_URL } from "../api/ApiManager";
import { phasesList } from "../assets/Data";
import { MdOutlineFeedback } from "react-icons/md";
// material ui
import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import AddPhaseModal from "../components/AddPhaseModal";
import Spinner from "../components/Spinner";
import FeedBackSeenModal from "../components/FeedBackSeenModal";
import BackButtonComp from "../components/BackButtonComp";

const ProductDetails = () => {
  const location = useLocation();

  const customerName = location?.state?.customerName;
  const projectId = location?.state?.projectId;
  const projectName = location?.state?.projectName;
  const InternalSrNo = location?.state?.internalSrNo;

  const [projectData, setprojectData] = useState<any>([]);
  const [phaseData, setphaseData] = useState([]);
  const [phaseModal, setphaseModal] = useState(false);
  const [openPhaseModal, setopenPhaseModal] = useState(false);
  const [apiLoader, setapiLoader] = useState(false);
  const [feedbackText, setfeedbackText] = useState("");
  const [openFeedback, setopenFeedback] = useState(false);

  useEffect(() => {
    const disableRightClick = (e: { preventDefault: () => any }) =>
      e.preventDefault();
    document.addEventListener("contextmenu", disableRightClick);

    const handleKeyDown = (e: {
      keyCode: number;
      preventDefault(): unknown;
      key: string;
    }) => {
      if (e.key === "PrintScreen") {
        alert("Screenshots are not allowed.");
        navigator.clipboard.writeText(""); // Clear clipboard
      } else if (e.key === "F11" || e.keyCode === 122) {
        e.preventDefault(); // Prevent default behavior (e.g., fullscreen)
        alert("Screenshots are not allowed.");
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    setapiLoader(true);
    const body = {
      id: projectId,
    };

    ApiManager.getProjectDetails(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          setprojectData(resp?.data?.data);
        }
        setapiLoader(false);
      })
      .catch(() => setapiLoader(false));
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = (index: number) => {
    // Update the active step when a step is clicked
    setActiveStep(index);
  };

  const savePhaseData = (e: any) => {
    let formdata = new FormData();
    formdata.append("projectId", projectData.project_id);
    formdata.append("name", e.name);
    formdata.append("start_date", e.start_date);
    formdata.append("end_date", e.end_date);
    formdata.append("description", e.description);
    formdata.append("dateCount", "0");
    e.image !== null
      ? formdata.append("image", e.image)
      : formdata.append("image", "");

    ApiManager.createPhase(formdata)
      .then((resp) => {
        if (resp?.data?.status == true) {
          getProjectDetails();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const renderOldDate = (item: any) => {
    const data = item;
    if (data) {
      return data.map((e: any, index: number) => (
        <div key={index} className="flex gap-4">
          <label>start date : {e.start_date}</label>
          <label>end date: {e.end_date}</label>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-3 sm:p-6">
          <BackButtonComp />
          <div className="text-gray-600 mb-2 text-sm sm:text-base">
            Dashboard &gt;
            <span className="font-semibold"> Customers &gt;</span>
            <span className="font-semibold"> {customerName} &gt;</span>
            <span className="font-semibold"> {projectName}</span>
          </div>
          <p className="text-xs sm:text-sm text-gray-600 mb-4">
            Track your projects with ease in just a few clicks
          </p>

          {/* Project Duration Box */}
          <div className="bg-white p-3 sm:p-4 rounded-lg shadow mb-6 space-y-3">
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-4">
                <span className="text-gray-600 text-xs sm:text-sm w-full sm:w-60">
                  Planned project duration
                </span>
                <div className="w-full bg-green-200 rounded-full h-2.5">
                  <div
                    className="bg-green-600 h-2.5 rounded-full"
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <span className="text-xs sm:text-sm font-medium w-20 text-center">
                  {projectData.project_duration} Days
                </span>
              </div>
            </div>
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-4">
                <span className="text-gray-600 text-xs sm:text-sm w-full sm:w-60">
                  Actual project duration
                </span>
                <div className="w-full bg-green-200 rounded-full h-2.5">
                  <div
                    className={`${
                      projectData.live_project_duration >
                      projectData.project_duration
                        ? "bg-red-600"
                        : "bg-green-600"
                    } h-2.5 rounded-full`}
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <span className="text-xs sm:text-sm font-medium w-20 text-center">
                  {projectData.live_project_duration} Days
                </span>
              </div>
            </div>
            {projectData.live_project_duration >
              projectData.project_duration && (
              <div>
                <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-4">
                  <span className="text-gray-600 text-xs sm:text-sm w-full sm:w-60">
                    Project delay
                  </span>
                  <div className="w-full bg-green-200 rounded-full h-2.5">
                    <div
                      className="bg-red-600 h-2.5 rounded-full"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <span className="text-xs sm:text-sm font-medium w-20 text-center">
                    {projectData.live_project_duration -
                      projectData.project_duration}{" "}
                    Days
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Phases Section */}
          <div className="bg-white p-3 sm:p-4 rounded-lg shadow">
            <div className="flex flex-col sm:flex-row justify-between">
              <h2 className="text-sm sm:text-lg font-semibold mb-4">
                {projectData.project_name} ({InternalSrNo})
              </h2>
              <button
                onClick={() => setopenPhaseModal(true)}
                className="px-3 h-8 sm:h-9 text-white rounded-lg bg-blue-500 hover:bg-blue-400 text-xs sm:text-sm"
              >
                Add more
              </button>
            </div>

            {/* Phases */}
            <div className="space-y-6">
              {projectData?.length !== 0 && (
                <Stepper activeStep={activeStep} orientation="vertical">
                  {projectData.phases.map((phase: any, index: number) => {
                    // Compare end_date with current date
                    const phaseEndDate = new Date(phase.end_date);
                    const EndDate = phaseEndDate.toISOString().split("T")[0];
                    const currentDate = new Date().toISOString().split("T")[0];
                    console.log("phaseEndDate", EndDate);
                    console.log("currentDate", currentDate);
                    const isEndDateGreater = EndDate < currentDate;
                    console.log("isEndDateGreater", isEndDateGreater);

                    // Updated StepLabel styles
                    const stepLabelStyle = {
                      color: isEndDateGreater ? "#4CAF50" : "#DC2626", // green for future, red for past
                      fontWeight: isEndDateGreater ? "bold" : "normal",
                      fontSize: "16px",
                    };

                    return (
                      <Step
                        key={phase.label}
                        onClick={() => handleNext(index)}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: isEndDateGreater ? "#4CAF50" : "#DC2626",
                          },
                          "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                            {
                              color: "grey.500",
                            },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: isEndDateGreater ? "#4CAF50" : "#DC2626",
                          },
                          "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                            {
                              color: isEndDateGreater ? "#4CAF50" : "#DC2626",
                            },
                          "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                            {
                              fill: "white",
                            },
                        }}
                      >
                        <StepLabel style={stepLabelStyle}>
                          {phase.name}
                        </StepLabel>
                        <StepContent>
                          <div
                            key={index}
                            className="flex flex-col sm:flex-row items-start w-full"
                          >
                            <div className="flex-grow">
                              {phase?.old_date !== "" ? (
                                <div>
                                  <label className="text-xs sm:text-base text-gray-500">
                                    Old dates
                                  </label>
                                  <p className="text-xs sm:text-sm text-gray-500">
                                    {renderOldDate(phase?.old_date)}
                                  </p>
                                </div>
                              ) : null}

                              <div className="mt-2 flex flex-col sm:flex-row space-x-0 sm:space-x-4 w-full sm:w-2/3">
                                <div className="flex-1 p-2 bg-gray-100 rounded-xl border border-gray-300">
                                  <p className="text-xs sm:text-sm">
                                    {phase.start_date}
                                  </p>
                                </div>
                                <div className="flex-1 p-2 bg-gray-100 rounded-xl border border-gray-300 mt-2 sm:mt-0">
                                  <p className="text-xs sm:text-sm">
                                    {phase.end_date}
                                  </p>
                                </div>
                              </div>
                              <p className="mt-2 text-xs sm:text-sm text-gray-600">
                                {phase.description}
                              </p>
                              {phase.image !== "" && (
                                <img
                                  src={IMG_URL + phase.image}
                                  alt="Protected"
                                  onContextMenu={(e) => e.preventDefault()}
                                  className="w-32 sm:w-40 mt-2 border rounded-lg border-gray-500 pointer-events-none"
                                />
                              )}
                            </div>
                            <div className="flex gap-3 mt-2 sm:mt-0">
                              <button
                                onClick={() => {
                                  setphaseData(phase);
                                  setphaseModal(!phaseModal);
                                }}
                                className="ml-2 px-2 pb-1 pt-0.5 sm:px-3 text-white rounded bg-blue-500 hover:bg-blue-400 text-xs sm:text-sm"
                              >
                                Edit
                              </button>
                              {phase?.feedback?.message && (
                                <MdOutlineFeedback
                                  size={20}
                                  color="#606060"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setfeedbackText(phase?.feedback?.message);
                                    setopenFeedback(true);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </div>
          </div>
        </main>
      </div>

      <AddPhaseModal
        openModal={openPhaseModal}
        data={phasesList}
        setOpenModal={(e: boolean) => setopenPhaseModal(e)}
        getInputData={(e: any) => savePhaseData(e)}
      />

      <ProjectPhaseModal
        isOpen={phaseModal}
        onClose={(e: boolean) => setphaseModal(e)}
        data={phaseData}
        onSave={() => getProjectDetails()}
      />

      <FeedBackSeenModal
        isOpen={openFeedback}
        data={feedbackText}
        onClose={(e: boolean) => setopenFeedback(e)}
        onSave={(e: boolean) => setopenFeedback(e)}
      />

      <Spinner loading={apiLoader} />
    </div>
  );
};

export default ProductDetails;
