import * as React from "react";
import { useEffect, useState } from "react";
import ApiManager, { IMG_URL } from "../api/ApiManager";
import { useLocation } from "react-router-dom";
import SideDrawer from "../components/SideDrawer";
import CustomHeader from "../components/CustomHeader";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Spinner from "../components/Spinner";
import { RiFeedbackFill } from "react-icons/ri";
import FeedBackModal from "../components/FeedBackModal";
import { ToastContainer, toast } from "react-toastify";
import BackButtonComp from "../components/BackButtonComp";

const CProductDetails = () => {
  const location = useLocation();

  const projectId = location?.state?.projectId;
  const projectName = location?.state?.projectName;

  const [projectData, setprojectData] = useState<any>([]);
  const [apiLoader, setapiLoader] = useState(false);
  const [selectPhase, setselectPhase] = useState("");
  const [feedBack, setfeedBack] = useState("");
  const [feedbackModal, setfeedbackModal] = useState(false);

  useEffect(() => {
    const disableRightClick = (e: { preventDefault(): any }) => {
      e.preventDefault();
      document.addEventListener("contextmenu", disableRightClick);
    };
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  });

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    setapiLoader(true);
    const body = {
      id: projectId,
    };

    ApiManager.getProjectDetails(body)
      .then((resp) => {
        if (resp?.data?.status == true) {
          setprojectData(resp?.data?.data);
        } else {
        }
        setapiLoader(false);
      })
      .catch((error) => console.log("error", error));
  };

  // material ui
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (index: number) => {
    setActiveStep(index);
  };

  const onfeedBackSave = (e: string) => {
    const body = {
      phaseId: selectPhase,
      projectId: projectId,
      message: e,
    };
    ApiManager.addFeedBack(body)
      .then(() => {
        toast("feedback saved!");
        getProjectDetails();
      })
      .catch((error) => console.log("error", error));
    setfeedbackModal(false);
  };

  const renderOldDate = (item: any) => {
    const data = item;

    if (data) {
      return data.map((e: any, index: number) => (
        <div key={index} className="flex gap-4">
          <label>start date : {e.start_date}</label>
          <label>end date: {e.end_date}</label>
        </div>
      ));
    }
    return null;
  };

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <CustomHeader />
      <div className="flex h-screen bg-gray-100">
        <SideDrawer />

        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4 sm:p-6">
          <BackButtonComp />

          <div className="text-gray-600 mb-2 text-xs sm:text-sm md:text-lg">
            Dashboard &gt;
            <span className="font-semibold text-gray-900 text-lg">
              {" "}
              {projectName}
            </span>
          </div>
          <p className="text-xs sm:text-sm text-gray-600 mb-4">
            Track your projects with ease in just a few clicks
          </p>

          {/* Project Duration Box */}
          <div className="bg-white p-3 sm:p-4 rounded-lg shadow mb-6 space-y-3">
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-4">
                <span className="text-gray-600 text-xs sm:text-sm w-full sm:w-60">
                  Planned project duration
                </span>
                <div className="w-full bg-green-200 rounded-full h-2.5">
                  <div
                    className="bg-green-600 h-2.5 rounded-full"
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <span className="text-xs sm:text-sm font-medium w-20">
                  {projectData?.project_duration} Days
                </span>
              </div>
            </div>

            <div>
              <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-4">
                <span className="text-gray-600 text-xs sm:text-sm w-full sm:w-60">
                  Actual project duration
                </span>
                <div className="w-full bg-green-200 rounded-full h-2.5">
                  <div
                    className={`${
                      projectData.live_project_duration >
                      projectData.project_duration
                        ? "bg-red-600"
                        : "bg-green-600"
                    } h-2.5 rounded-full`}
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <span className="text-xs sm:text-sm font-medium w-20">
                  {projectData.live_project_duration} Days
                </span>
              </div>
            </div>

            {projectData.live_project_duration >
              projectData.project_duration && (
              <div>
                <div className="flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-4">
                  <span className="text-gray-600 text-xs sm:text-sm w-full sm:w-60">
                    Project delay
                  </span>
                  <div className="w-full bg-green-200 rounded-full h-2.5">
                    <div
                      className="bg-red-600 h-2.5 rounded-full"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <span className="text-xs sm:text-sm font-medium w-20">
                    {projectData.live_project_duration -
                      projectData.project_duration}{" "}
                    Days
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Phases Box */}
          <div className="bg-white p-3 sm:p-4 rounded-lg shadow">
            <div className="space-y-4 sm:space-y-6">
              {projectData?.length !== 0 && (
                <div>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {projectData.phases.map((phase: any, index: number) => {
                      // Compare end_date with current date
                      const phaseEndDate = new Date(phase.end_date);
                      const EndDate = phaseEndDate.toISOString().split("T")[0];
                      const currentDate = new Date()
                        .toISOString()
                        .split("T")[0];
                      console.log("phaseEndDate", EndDate);
                      console.log("currentDate", currentDate);
                      const isEndDateGreater = EndDate < currentDate;
                      console.log("isEndDateGreater", isEndDateGreater);

                      // Updated StepLabel styles
                      const stepLabelStyle = {
                        color: isEndDateGreater ? "#4CAF50" : "#DC2626", // green for future, red for past
                        fontWeight: isEndDateGreater ? "bold" : "normal",
                        fontSize: "16px",
                      };
                      return (
                        <Step
                          key={phase.label}
                          onClick={() => {
                            handleNext(index);
                          }}
                          sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: isEndDateGreater ? "#4CAF50" : "#DC2626",
                            },
                            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                              {
                                color: "grey.500",
                              },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: isEndDateGreater ? "#4CAF50" : "#DC2626",
                            },
                            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                              {
                                color: isEndDateGreater ? "#4CAF50" : "#DC2626",
                              },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                              {
                                fill: "white",
                              },
                          }}
                        >
                          <div className="flex justify-between items-center">
                            <StepLabel style={stepLabelStyle}>
                              {phase.name}
                            </StepLabel>
                            <RiFeedbackFill
                              size={20}
                              className="cursor-pointer"
                              onClick={() => {
                                setselectPhase(phase.id),
                                  setfeedBack(phase?.feedback?.message);
                                setfeedbackModal(true);
                              }}
                            />
                          </div>
                          <StepContent>
                            <div
                              key={index}
                              className="flex flex-col sm:flex-row items-start w-full"
                            >
                              <div className="flex-grow">
                                {phase?.old_date !== "" ? (
                                  <div>
                                    <label className="text-xs sm:text-base text-gray-500">
                                      Old dates
                                    </label>
                                    <p className="text-xs sm:text-sm text-gray-500">
                                      {renderOldDate(phase?.old_date)}
                                    </p>
                                  </div>
                                ) : null}

                                <div className="mt-2 flex flex-wrap space-x-2 sm:space-x-4 w-full sm:w-2/3">
                                  <div className="flex-1 p-2 bg-gray-100 rounded-xl border border-gray-300 text-xs sm:text-sm">
                                    <p>{phase.start_date}</p>
                                  </div>
                                  <div className="flex-1 p-2 bg-gray-100 rounded-xl border border-gray-300 text-xs sm:text-sm">
                                    <p>{phase.end_date}</p>
                                  </div>
                                </div>

                                <p className="mt-2 text-xs sm:text-sm text-gray-600">
                                  {phase.description}
                                </p>
                                {phase.image !== "" && (
                                  <img
                                    src={IMG_URL + phase.image}
                                    className="w-32 sm:w-40 mt-2 border rounded-lg border-gray-500"
                                    alt="Protected"
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                )}
                              </div>
                            </div>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>

      <FeedBackModal
        isOpen={feedbackModal}
        data={feedBack}
        onClose={(e: boolean) => setfeedbackModal(e)}
        onSave={(e: any) => onfeedBackSave(e)}
      />
      <ToastContainer />
      <Spinner loading={apiLoader} />
    </div>
  );
};

export default CProductDetails;
