import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import NotificationProvider from "./components/NotificationProvider";

/** TO DISABLE SCREEN CAPTURE **/
document.addEventListener("keyup", (e) => {
  if (e.key == "PrintScreen") {
    navigator.clipboard.writeText("");
    alert("Screenshots disabled!");
  }
});

/** TO DISABLE PRINTS WHIT CTRL+P **/
document.addEventListener("keydown", (e) => {
  if (e.key && e.key == "f11") {
    e.preventDefault();
    alert("Fullscreen disabled!");
  }
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Provider store={store}>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </Provider>
  </StrictMode>
);
