import React, { Key, ReactNode, useEffect, useRef } from "react";
import { format } from "date-fns";
import ApiManager from "../api/ApiManager";

interface Props {
  openModal: boolean;
  onClose: Function;
  notifications: any;
  updateNotify: Function;
}

const NotificationModal = ({
  openModal,
  onClose,
  notifications,
  updateNotify,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isReadmessage, setIsReadmessage] = React.useState(false);

  useEffect(() => {
    MarkAsReadAPI();
  }, [openModal]);

  const MarkAsReadAPI = () => {
    const ID = notifications.map((notification: any) => notification.id);
    const params = {
      data: ID,
    };

    ApiManager.markAsRead(params)
      .then((res) => {
        if (res?.data?.status === true) {
          updateNotify();
          setIsReadmessage(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!openModal) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => onClose(!openModal)}
        />
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          ref={modalRef}
          className="absolute inset-x-5 top-6 transform rounded-lg bg-white shadow-lg p-4 sm:top-5 sm:right-5 sm:inset-x-auto sm:translate-y-0 sm:w-96"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          tabIndex={-1}
        >
          <div className="flex flex-col items-center">
            <div className="w-full mb-4 flex justify-between items-center">
              <h2 className="text-gray-900 text-lg font-bold">Notifications</h2>
              <button
                className="text-blue-500 hover:underline"
                onClick={() => setIsReadmessage(true)}
              >
                Mark all as read
              </button>
            </div>
            <div className="w-full max-h-96 overflow-y-auto">
              {notifications.length > 0 ? (
                notifications.map(
                  (
                    notification: {
                      createdAt: string | number | Date;
                      title: ReactNode;
                      message: ReactNode;
                      avatarUrl?: string;
                    },
                    index: Key | null | undefined
                  ) => {
                    const formattedDate = format(
                      new Date(notification.createdAt),
                      "MMM d, yyyy h:mm a"
                    );

                    return (
                      <div
                        key={index}
                        className="flex items-start space-x-3 bg-white border-b border-gray-200 p-3 hover:bg-gray-100"
                      >
                        <div className="flex-1">
                          <div className="flex justify-between">
                            <h4
                              className={
                                isReadmessage
                                  ? "text-gray-500 font-semibold"
                                  : "text-black font-semibold"
                              }
                            >
                              {notification.title}
                            </h4>
                            {!isReadmessage && (
                              <span className="w-2 h-2 bg-blue-500 rounded-full" />
                            )}
                          </div>
                          <p
                            className={
                              isReadmessage
                                ? "text-gray-500 text-sm"
                                : "text-black text-sm"
                            }
                          >
                            {notification.message}
                          </p>
                          <p className="text-gray-400 text-xs">
                            {formattedDate}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="text-black text-lg font-semibold">
                  No notifications available.
                </div>
              )}
            </div>
            {/* <div className="w-full mt-4 flex justify-between">
              <button className="text-blue-500 hover:underline">
                Mark all as read
              </button>
              <button className="text-blue-500 hover:underline">
                View all notifications
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
