import { X } from "lucide-react";

interface props {
  isOpen: boolean;
  isClose: Function;
  onSave: Function;
  text: String;
}

const DeleteModal = ({ isOpen, isClose, onSave, text }: props) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-xs md:max-w-sm lg:max-w-md p-4">
        <div className="flex justify-between">
          <h1 className="font-semibold text-lg">Confirm delete</h1>
          <X
            className="w-5 h-5 cursor-pointer"
            onClick={() => isClose(false)}
          />
        </div>
        <div className="border mt-2 p-2 rounded-lg">
          <h2 className="font-semibold">{text}</h2>

          <div className="flex justify-end gap-2 md:gap-4">
            <div className="mt-4">
              <button
                onClick={() => isClose(false)}
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Close
              </button>
            </div>
            <div className="mt-4">
              <button
                onClick={() => onSave()}
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
