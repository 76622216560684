import { MdNotifications } from "react-icons/md";
import more from "../assets/images/more.png";
import whiteLogo from "../assets/images/logo-white.png";
import { useDispatch } from "react-redux";
import { toggleSideDrawer } from "../redux/reducer/side_drawer";
import { useEffect, useState } from "react";
import ProfileModal from "./ProfileModal";
import NotificationModal from "./NotificationModal";
import ApiManager from "../api/ApiManager";

interface Notification {
  isNotifyCustomer: number;
  isRead: boolean;
}

const CustomHeader: React.FC = () => {
  const dispatch = useDispatch();
  const userData = localStorage.getItem("userData");
  const userExist = userData ? JSON.parse(userData) : null;

  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const handlePress = () => {
    dispatch(toggleSideDrawer());
  };

  useEffect(() => {
    NotificationAPI();
  }, []);

  const NotificationAPI = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const userId = userData?.id;

      if (userId) {
        const res = await ApiManager.getNotification(userId);
        if (res?.data?.status === true) {
          const response = res?.data?.data;
          setNotifications(response);

          setNotificationCount(res?.data?.unReadCount);
        } else {
          console.error("Error: Unexpected response status", res?.data?.status);
        }
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  return (
    <header className="flex justify-between items-center py-2 pb-[1rem] px-5 bg-red-600 text-white">
      <div className="flex items-center">
        <div className="p-2 flex justify-center items-center border-b border-white">
          <img
            src={whiteLogo}
            className="w-10 h-10 object-contain"
            alt="Protected"
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <button
          className="text-2xl"
          aria-label="Toggle menu"
          onClick={handlePress}
        >
          <img
            src={more}
            className="w-8 h-8 object-cover"
            alt="Protected"
            onContextMenu={(e) => e.preventDefault()}
          />
        </button>
        <div className="text-xl ml-3">
          Welcome! {userExist?.userType === 0 ? userExist?.name : "Admin"}
        </div>
      </div>

      <div className="flex items-center">
        {/* Notification Icon with Badge */}
        {userExist?.status == 1 ? (
          <div
            className="mr-4 relative cursor-pointer"
            onClick={() => {
              setShowNotificationModal(true);
            }}
            style={{ position: "relative" }}
          >
            <MdNotifications size={30} color="white" />
            {notificationCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "12px",
                  transform: "translate(50%, -50%)",
                }}
              >
                {notificationCount}
              </span>
            )}
          </div>
        ) : null}

        {/* Profile Icon */}
        <div
          onClick={() => setShowProfileModal(true)}
          className="w-8 h-8 pb-1 rounded-full bg-white text-red-600 flex items-center justify-center font-bold cursor-pointer"
        >
          {userExist?.status == 1 ? userExist?.name.charAt(0) : "A"}
        </div>
      </div>

      {/* Modals */}
      <NotificationModal
        openModal={showNotificationModal}
        notifications={notifications}
        updateNotify={() => NotificationAPI()}
        onClose={(e: boolean) => setShowNotificationModal(e)}
      />
      <ProfileModal
        openModal={showProfileModal}
        onClose={(e: boolean) => setShowProfileModal(e)}
      />
    </header>
  );
};

export default CustomHeader;
